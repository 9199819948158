import { ReactComponent as Guru } from 'app-images/animation/guru.svg'
import classNames from 'classnames'
import React, { useRef } from 'react'
import { useDispatch } from 'react-redux'

import { setReloadRefresh } from '../actions'
import { LOADER_TIMEOUT } from '../constants'
import { useComponentDidMount } from '../hooks/useComponentDidMount'

interface LoaderProps {
  modifier?: string
  onTimeout?: () => void
  isDashboard?: boolean
  infinity?: boolean
}

const Loader: React.FC<LoaderProps> = ({
  modifier,
  onTimeout,
  isDashboard = true,
  infinity = false,
}) => {
  const timeoutIntervalRef = useRef<ReturnType<typeof setInterval> | undefined>(undefined)
  const reduxDispatch = useDispatch()
  useComponentDidMount(() => {
    if (infinity) {
      return
    }

    timeoutIntervalRef.current = setInterval(() => {
      reduxDispatch(setReloadRefresh(true, isDashboard))
      onTimeout && onTimeout()
    }, LOADER_TIMEOUT)

    return (): void => {
      reduxDispatch(setReloadRefresh(false, false))
      timeoutIntervalRef.current && clearInterval(timeoutIntervalRef.current)
      timeoutIntervalRef.current = undefined
    }
  })

  return (
    <>
      <div className={classNames(`loader-guru`, modifier ? `loader-guru--${modifier}` : '')}>
        <div className="loader-guru__container">
          <div className="loader-guru__rainbow">
            <div className="loader-rainbow">
              <div className="loader-rainbow__line" />
              <div className="loader-rainbow__line" />
              <div className="loader-rainbow__line" />
              <div className="loader-rainbow__line" />
              <div className="loader-rainbow__line" />
            </div>
          </div>
          <div className="loader-guru__avatar">
            <Guru />
          </div>
        </div>
      </div>
    </>
  )
}

export default Loader
